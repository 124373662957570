import { createTheme } from '@mui/material/styles';

const Light = createTheme({
    typography: {
        "fontFamily": `"Roboto", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

export default Light;
