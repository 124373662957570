import React from 'react';
import {Container} from "@mui/material";
import Box from '@mui/material/Box';
import donateArtifact from '../abis/Donate.json';
import {ethers} from "ethers";
import {useEthers} from "@usedapp/core";
import {useHistory} from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DonationCards from "./cards";

const donateAddress = "0x518653C6DE68D869c38481Ae6e2fA513D0C9b48B"
const tier1Donation = '0.05';
const tier2Donation = '0.1';
const tier3Donation = '0.2';
const tier4Donation = '0.4';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Index = () => {
    const history = useHistory();
    const {library, chainId} = useEthers();
    const [tier5Donation, setTier5Donation] = React.useState(0);
    const [isDonating, setIsDonating] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleDonation = async(price, url, functionToDonate) => {
        setIsDonating(true);
        const donateContract = new ethers.Contract(donateAddress, donateArtifact.abi, library.getSigner());
        const priceToDonate = ethers.utils.parseUnits(price, 'ether');
        try{
            const transaction = await donateContract[functionToDonate](url, {
                value: priceToDonate
            });
            await transaction.wait();
            history.push('/thank-you');
        } catch (err) {
            let errorMessage = err?.message || 'Something went wrong';
            if(err.code === 'INSUFFICIENT_FUNDS') {
                errorMessage = 'Wallet has insufficient funds';
            }
            if(err.code === 'UNPREDICTABLE_GAS_LIMIT') {
                errorMessage = 'Unpredictable gas limit';
            }
            setError(errorMessage);
            console.log('error: ', err);
        } finally {
            setIsDonating(false);
        }
    }

    return <Container maxWidth={'xl'} sx={{mb: '100px'}}>
        <Box sx={{textAlign: 'center', mt: '100px'}}>
            <h1 style={{fontWeight: 700, fontSize: '50px', letterSpacing: '0px', color: 'rgb(4, 17, 29)'}}>Help
                Ukraine</h1>
        </Box>
        <Box sx={{textAlign: 'center', mt: '44px', fontWeight: '400', fontSize: '20px', color: '#2D2D2D'}}>
            Vestibulum pharetra rhoncus diam, eget vehicula magna eleifend nec.
        </Box>

        <DonationCards.Donation1 onClick={()=>handleDonation(tier1Donation, 'https://bafybeigxdeniokwsfjtcat7qfbjnvvqa6sgklfy6w7db6xdhb4zwj7jfkm.ipfs.infura-ipfs.io/', 'tier_1_donation')} price={tier1Donation} chainId={chainId} isDonating={isDonating} />
        <DonationCards.Donation2 onClick={()=>handleDonation(tier2Donation, 'https://bafybeib22cwjqptig6dl3joptmmfupjbwjeya4mgo3yavb2g7r5d7a2xjm.ipfs.infura-ipfs.io/', 'tier_2_donation')} price={tier2Donation} chainId={chainId} isDonating={isDonating} />
        <DonationCards.Donation3 onClick={()=>handleDonation(tier3Donation, 'https://bafybeihs5rfwalkhoy3mtbu2r2ndohmmlaadujpztqra25xffyee3pfo7e.ipfs.infura-ipfs.io/', 'tier_3_donation')} price={tier3Donation} chainId={chainId} isDonating={isDonating} />
        <DonationCards.Donation4 onClick={()=>handleDonation(tier4Donation, 'https://bafybeihs5rfwalkhoy3mtbu2r2ndohmmlaadujpztqra25xffyee3pfo7e.ipfs.infura-ipfs.io/', 'tier_4_donation')} price={tier4Donation} chainId={chainId} isDonating={isDonating} />
        <DonationCards.Donation5 onClick={()=>handleDonation(tier5Donation.toString(), 'https://bafybeihs5rfwalkhoy3mtbu2r2ndohmmlaadujpztqra25xffyee3pfo7e.ipfs.infura-ipfs.io/', 'tier_5_donation')} setTier5Donation={value=>setTier5Donation(value || 0)} chainId={chainId} isDonating={isDonating} />

        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            severity="info"
            open={!!error}
            autoHideDuration={6000}
            onClose={()=>{setError(null)}}

        >
                <Alert onClose={() => setError(null)} severity="info">{error}</Alert>
        </Snackbar>
    </Container>
};

export default Index;