import AppBar from './AppBar';
import SideDrawer from './SideDrawer';
import Confirm from './Confirm';
import ConnectWallet from './ConnectWallet';
const Components = {
    AppBar,
    SideDrawer,
    Confirm,
    ConnectWallet
};

export default Components;
 