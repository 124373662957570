import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const Confirm = ({title, content, onClose}) => {
    return <Dialog onClose={onClose} open={true}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText >
                {content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} autoFocus sx={{color:'#FFFFFF', textTransform: 'unset', fontWeight: 800, fontSize: '12px', fontFamily: 'Open Sans', width: '100px', height: '36px', borderRadius: '8px', padding: '10px', background: 'linear-gradient(325deg, #95c11f 20%, #3AAA35 90%);'}} >
                OK
            </Button>
        </DialogActions>
    </Dialog>
};

export default Confirm;