import Donation1 from './Donation1';
import Donation2 from './Donation2';
import Donation3 from './Donation3';
import Donation4 from './Donation4';
import Donation5 from './Donation5';

const DonationCards = {
    Donation1,
    Donation2,
    Donation3,
    Donation4,
    Donation5,
};

export default DonationCards