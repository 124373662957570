import { useEffect, useState } from 'react';
import axios from 'axios';

//import Auth from '@hazellabs/qauth';

const useGateway = (config = { baseURL: `${window.env.GATEWAY}` }) => {
    const [instance] = useState(() => axios.create(config));
    //const { token, logout } = Auth.useAuth();

    useEffect(() => {
        instance?.interceptors?.request?.use(async (request) => {
            //request.headers.Authorization = `Bearer ${token}`;

            return request;
        }, error => {
            return Promise.reject(error)
        });

        instance?.interceptors?.response?.use(response => response, error => {
            if (error.response.status === 401) {
                console.log('401 error');
                //logout();
            }
            return error;
        });

    }, [instance]);

    return instance;
};

export default useGateway;
