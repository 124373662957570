import React from 'react';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import LinearProgress from "@mui/material/LinearProgress";

const DonateButton = ({isDonating, onClick, chainId, disabled}) => {
    return <Button sx={{
        color: '#FFFFFF',
        textTransform: 'unset',
        fontWeight: 800,
        fontSize: '16px',
        fontFamily: 'Open Sans',
        width: '180px',
        height: '56px',
        borderRadius: '8px',
        padding: '10px',
        background: 'linear-gradient(325deg, #95c11f 20%, #3AAA35 90%);'
    }}
                   onClick={onClick}
                   disabled={chainId !== 4 || isDonating || disabled}
                   size="small"
                   aria-label="show 17 new notifications"
                   color="inherit"
    >

        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {!isDonating && <Box>
                Donate now
            </Box>}
            {isDonating && <Box>
                Sending Donation
            </Box>}
            {isDonating && <Box sx={{width: '100%', color: 'grey.500'}}>
                <LinearProgress color="success"/>
            </Box>}
        </Box>
    </Button>
};

export default DonateButton;