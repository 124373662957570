import React from 'react';
import Box from "@mui/material/Box";
import EthIcon from "../../static/ethereum-icon.webp";
import DonateButton from "../DonateButton";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@mui/styles";
import useEthPrice from "../useEthPrice";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
    card: {
        '&:hover': {
            boxShadow: 'rgb(4 17 29 / 25%) 0px 0px 8px 0px',
            transition: 'all 0.1s ease 0s'
        }
    },
    cardAction: {
        '&:hover': {}
    },
    bullets: {
        paddingLeft: '15px'
    }
}));

const Donation5 = ({onClick, isDonating, chainId, setTier5Donation}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [error, setError] = React.useState('');
    const {convertEthToEur, isFetchingEtherPrice} = useEthPrice();

    React.useEffect(()=> {
        setTier5Donation(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return <Paper elevation={5} sx={{mt: '95px', padding: '35px'}}>
        <Box sx={{display: 'flex',  flexDirection: {xs: 'column', md: 'row'}}}>
            <Box sx={{
                borderRadius: '8px',
                width: '100%',
                maxWidth: '400px',
                maxHeight: '450px',
                margin: '0 auto',
                textAlign: 'center'
            }}>
                <img alt={'nft 5 from ipfs'}  width={'100%'} src={'https://ipfs.infura.io/ipfs/QmRKjcWjvuJgoSERGa53oQy7wFkbjFcByJs45RAgVqxRJe'}/>
            </Box>
            <Box sx={{padding: {xs: '0', md: '0 55px'}}}>
                <Box>
                    <h1>Help Ukraine #5</h1>
                </Box>
                <Box sx={{mt: '30px'}}>
                    <span>Sed quis eros viverra, auctor urna vitae, tempor justo. Etiam nunc nibh, ultricies non porttitor eu, auctor sit amet nibh. Donec ornare libero vitae lacus blandit venenatis. </span>
                </Box>
                <Box sx={{position: 'relative', display: {xs: 'flex', md: 'none'}, margin: '25px 0px'}}>
                    <Box sx={{display: 'flex', mb: '9px', flexDirection: 'column', justifyContent: 'flex-end'}}>
                        <span>Price offer</span>
                    </Box>

                    <Box sx={{ml: '10px', color: '#95C11F', fontWeight: '700', fontSize: '50px'}}>
                        <TextField placeholder={'min. 0.8 ETH'} error={!!error} sx={{width: '150px'}} type={'number'}
                                   onChange={(e) => {
                                       if(e.target.value < 0.8) {
                                           setError('Minimum donation is 0.8 ETH');
                                       } else {
                                           setError('');
                                           setValue(e.target.value);
                                       }
                                   }} />
                    <Box sx={{position: 'absolute',  bottom: '-18px', right: '25px', fontStyle: 'italic', textAlign: 'right', color: '#777777',  fontSize: '14px'}}>
                        {!isFetchingEtherPrice && error === '' && <span>Fiat price: {convertEthToEur(value).toFixed(1)} €</span>}
                        {error && <span style={{color: '#ff0000d4'}}>{error}</span>}
                    </Box>
                    </Box>
                    <Box sx={{ml: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <img alt={'Ethereum icon'} src={EthIcon} style={{width: '30px'}}/>
                    </Box>
                </Box>
                <Box sx={{mt: '20px', borderTop: '1px solid #EFF0F1', borderBottom: '1px solid #EFF0F1'}}>
                    <ul className={classes.bullets}>
                        <li style={{marginTop: '25px', color: '#95C11F'}}><span style={{color: 'black'}}>Vestibulum pharetra rhoncus diam, eget vehicula magna eleifend nec.</span>
                        </li>
                        <li style={{marginTop: '25px', color: '#95C11F'}}><span style={{color: 'black'}}>Donec ornare libero.</span>
                        </li>
                        <li style={{marginTop: '25px', marginBottom: '25px', color: '#95C11F'}}><span
                            style={{color: 'black'}}>Cras odio sem, mattis sollicitudin consequat vitae</span></li>
                    </ul>
                </Box>
                <Box sx={{mt: '35px', display: 'flex', justifyContent: 'space-between'}}>
                    <DonateButton disabled={!!error || value===0} onClick={(onClick)} chainId={chainId} isDonating={isDonating} />

                    <Box sx={{position: 'relative',display: {xs: 'none', md: 'flex'}}}>
                        <Box sx={{display: 'flex', mb: '9px', flexDirection: 'column', justifyContent: 'flex-end'}}>
                            <span>Price offer</span>
                        </Box>

                        <Box sx={{ml: '10px', color: '#95C11F', fontWeight: '700', fontSize: '50px'}}>
                            <TextField placeholder={'min. 0.8 ETH'} error={!!error} sx={{width: '150px'}} type={'number'}
                                       onChange={(e) => {
                                           if(e.target.value < 0.8) {
                                               setError('Minimum donation is 0.8 ETH');
                                           } else {
                                               setError('');
                                               setValue(e.target.value);
                                           }
                                       }} />
                            <Box sx={{position: 'absolute',  bottom: '-18px', right: '13px', fontStyle: 'italic', textAlign: 'right', color: '#777777',  fontSize: '14px'}}>
                                {!isFetchingEtherPrice && error === '' && <span>Fiat price: {convertEthToEur(value).toFixed(1)} €</span>}
                                {error && <span style={{color: '#ff0000d4'}}>{error}</span>}
                            </Box>
                        </Box>
                        <Box sx={{ml: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <img alt={'Ethereum icon'} src={EthIcon} style={{width: '30px'}}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Paper>
};

export default Donation5;