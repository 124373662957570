import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import WifiTetheringErrorIcon from "@mui/icons-material/WifiTetheringError";
import Dialog from "./Confirm";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import {useEthers} from "@usedapp/core";

const ConnectWallet = () => {
    const { account, activate, chainId } = useEthers();
    const [wrongNetworkDialog, setWrongNetworkDialog] = React.useState(false);

    React.useEffect(()=>{
        (async () => {
            if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) await activateProvider();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const activateProvider = async () => {
        const providerOptions = {
            injected: {
                display: {
                    name: 'Metamask',
                    description: 'Connect with the provider in your Browser',
                },
                package: null,
            },
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    bridge: 'https://bridge.walletconnect.org',
                    infuraId: '371b70dc777746cb88d0a2d7dd707647',
                },
            },
        }

        const web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions,
        })
        try {
            const provider = await web3Modal.connect();

            // // Subscribe to accounts change
            // provider.on("accountsChanged", (accounts) => {
            //     console.log("accountsChanged: ", accounts);
            // });
            //
            // Subscribe to chainId change
            provider.on("chainChanged", () => {
                //Needed tp update provider when switching between networks.
                //Issue was in SideDrawer when calling useEtherBalance hook.
                //When switched from Polygon network to Rinkeby, useEtherBalance
                //still internally used Polygon provider and returned wrong wallet balance.
                 activate(provider);
            });
            //
            // // Subscribe to provider connection
            // provider.on("connect", (info) => {
            //     console.log("connect: ", info);
            // });
            //
            // // Subscribe to provider disconnection
            // provider.on("disconnect", (error) => {
            //     console.log("disconnect: ", error);
            // });
            await activate(provider)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <React.Fragment>
            {!account && <Box sx={{display: "flex", justifyContent: 'end'}}><Box
                sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Button sx={{
                    textTransform: 'unset',
                    fontWeight: 800,
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    width: '150px',
                    height: '46px',
                    borderRadius: '8px',
                    padding: '10px',
                    background: 'linear-gradient(325deg, #95c11f 20%, #3AAA35 90%);'
                }}
                        onClick={() => activateProvider()}
                        size="small"
                        aria-label="connect wallet"
                        variant='contained'
                >
                    Connect Wallet
                </Button>
            </Box></Box>}
            {chainId!==4 && chainId !== undefined && <Box sx={{display: "flex", justifyContent: 'end'}}><Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
                <Button sx={{ border: '1px solid rgb(255, 67, 67)', fontWeight: '500', textTransform: 'unset', fontSize: '16px', fontFamily: 'Open Sans', width: '180px', height: '56px', borderRadius: '8px', padding: '10px'}}
                        onClick={()=>setWrongNetworkDialog(true)}
                        size="small"
                        variant="contained"
                        aria-label="wrong network"
                        color="error"

                >
                    <WifiTetheringErrorIcon />
                    <Box sx={{ml: '5px'}}>Wrong Network</Box>
                </Button>
            </Box></Box>}
            {wrongNetworkDialog && <Dialog title={'Wrong Network'} content={'Please connect to Ethereum network in your wallet.'} onClose={()=>setWrongNetworkDialog(false)} />}
        </React.Fragment>
)};

export default ConnectWallet;