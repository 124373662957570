import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Container} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import Logo from "../static/logo-full.png";
import Button from "@mui/material/Button";

const Index = () => {
    return <Container maxWidth={'md'} sx={{mb: '100px', height: '100vh'}}>
        <Paper sx={{textAlign: 'center', mt: '100px'}}>
            <Box sx={{padding: {xs: '20px', md: '77px'}}}>
                <FavoriteIcon sx={{fontSize: '150px', color: '#95c11f'}}/>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{display: 'flex',  flexDirection: {xs: 'column', md: 'row'}}}>
                        <Box sx={{display: 'flex', flexDirection: {xs: 'row', md: 'column'}, justifyContent: 'center'}}>
                            <img src={Logo} width={'180px'} height={'41px'} alt={'logo'}/>
                        </Box>
                        <Box sx={{display: {xs: 'none', md: 'block'}}}>
                            <span style={{fontSize: '50px', fontWeight: '700'}}>, you care!</span>
                        </Box>
                        <Box sx={{display: {xs: 'bock', md: 'none'}}}>
                            <span style={{fontSize: '50px', fontWeight: '700'}}>you care!</span>
                        </Box>
                    </Box>

                </Box>
                <Box sx={{textAlign: 'center', mt: '25px', fontWeight: '400', fontSize: '28px', color: '#2D2D2D'}}>
                    Your generous support will help change lives.
                </Box>
                <Box sx={{mt: '70px', display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                        <Button sx={{
                            textTransform: 'unset',
                            fontWeight: 800,
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                            width: '150px',
                            height: '46px',
                            borderRadius: '8px',
                            padding: '10px',
                            background: 'linear-gradient(325deg, #95c11f 20%, #3AAA35 90%);'
                        }}
                                onClick={() =>window.open('https://wecare.qvrse.io/', '_blank')}
                                size="small"
                                aria-label="connect wallet"
                                variant='contained'
                        >
                            Visit
                        </Button>
                        <Button sx={{
                            textTransform: 'unset',
                            fontWeight: 800,
                            fontSize: '14px',
                            fontFamily: 'Open Sans',
                            width: '150px',
                            height: '46px',
                            borderRadius: '8px',
                            mt: {xs: '20px', md: '0px'},
                            ml:{xs: '0px', md: '17px'},
                            padding: '10px',
                            background: '#000000'
                        }}
                                onClick={() =>window.open('https://ukraine.qvrse.io/story/', '_blank')}
                                size="small"
                                aria-label="connect wallet"
                                variant='contained'
                        >
                            Learn more
                        </Button>
                    </Box>

                </Box>
                <Box sx={{padding: '35px 0', mt: '90px', textAlign: 'left', borderTop: '1px solid lightgray'}}>
                    <span style={{fontSize: '18px', fontWeight: '400', color: '#878787'}}>**It’s thanks to people like you that we can continue our fight. We will ensure it is spent wisely with maximum impact on the lives of people infected by injustice.</span>
                </Box>
            </Box>
        </Paper>


    </Container>
};

export default Index;
