import React from 'react';
import Box from "@mui/material/Box";
import EthIcon from "../../static/ethereum-icon.webp";
import DonateButton from "../DonateButton";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@mui/styles";
import useEthPrice from "../useEthPrice";

const useStyles = makeStyles((theme) => ({
    card: {
        '&:hover': {
            boxShadow: 'rgb(4 17 29 / 25%) 0px 0px 8px 0px',
            transition: 'all 0.1s ease 0s'
        }
    },
    cardAction: {
        '&:hover': {}
    },
    bullets: {
        paddingLeft: '15px'
    }
}));

const Donation2 = ({onClick, isDonating, chainId, price}) => {
    const classes = useStyles();
    const {convertEthToEur, isFetchingEtherPrice} = useEthPrice();

    return <Paper elevation={5} sx={{mt: '95px', padding: '35px'}}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
            <Box sx={{
                borderRadius: '8px',
                width: '100%',
                textAlign: 'center',
                maxWidth: '400px',
                maxHeight: '450px',
                margin: '0 auto'
            }}>
                <img  alt={'nft 2 from ipfs'} width={'100%'} src={'https://ipfs.infura.io/ipfs/QmPbgXny487EmCgMtgg7kG1nFDTGbDXFCbdfTRJ1DnxbVD'}/>
            </Box>
            <Box sx={{padding: {xs: '0', md: '0 55px'}}}>
                <Box>
                    <h1>Help Ukraine #2</h1>
                </Box>
                <Box sx={{mt: '30px'}}>
                    <span>Sed quis eros viverra, auctor urna vitae, tempor justo. Etiam nunc nibh, ultricies non porttitor eu, auctor sit amet nibh. Donec ornare libero vitae lacus blandit venenatis. </span>
                </Box>
                <Box sx={{position: 'relative',display: {xs: 'flex', md: 'none'}, margin: '25px 0px'}}>
                    <Box sx={{display: 'flex', mb: '9px', flexDirection: 'column', justifyContent: 'flex-end'}}>
                        <span>Price offer</span>
                    </Box>

                    <Box sx={{ ml: '10px', color: '#95C11F', fontWeight: '700', fontSize: '50px'}}>
                        <span>{price}</span>
                        <Box sx={{position: 'absolute', bottom: '-15px', right: '105px', textAlign: 'right', color: '#777777', fontStyle: 'italic',  fontSize: '14px'}}>
                            {!isFetchingEtherPrice && <span>Fiat price: {convertEthToEur(0.05).toFixed(1)} €</span>}
                        </Box>
                    </Box>
                    <Box sx={{ml: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <img alt={'Ethereum icon'} src={EthIcon} style={{width: '30px'}}/>
                    </Box>
                </Box>
                <Box sx={{mt: '20px', borderTop: '1px solid #EFF0F1', borderBottom: '1px solid #EFF0F1'}}>
                    <ul className={classes.bullets}>
                        <li style={{marginTop: '25px', color: '#95C11F'}}><span style={{color: 'black'}}>Vestibulum pharetra rhoncus diam, eget vehicula magna eleifend nec.</span>
                        </li>
                        <li style={{marginTop: '25px', color: '#95C11F'}}><span style={{color: 'black'}}>Donec ornare libero.</span>
                        </li>
                        <li style={{marginTop: '25px', marginBottom: '25px', color: '#95C11F'}}><span
                            style={{color: 'black'}}>Cras odio sem, mattis sollicitudin consequat vitae</span></li>
                    </ul>
                </Box>
                <Box sx={{position: 'relative', mt: '35px', display: 'flex', justifyContent: 'space-between'}}>
                    <DonateButton onClick={onClick} chainId={chainId} isDonating={isDonating} />
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Box sx={{display: 'flex', mb: '9px', flexDirection: 'column', justifyContent: 'flex-end'}}>
                            <span>Price offer</span>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: '10px', color: '#95C11F', fontWeight: '700', fontSize: '50px'}}>

                            <span>{price}</span>
                            <Box sx={{position: 'absolute', bottom: '-15px', right: '12px', textAlign: 'right', color: '#777777', fontStyle: 'italic',  fontSize: '14px'}}>
                                {!isFetchingEtherPrice && <span>Fiat price: {convertEthToEur(0.05).toFixed(1)} €</span>}
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <img alt={'Ethereum icon'} src={EthIcon} style={{width: '30px'}}/>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    </Paper>
};

export default Donation2;