import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import EthIcon from '../../static/ethereum-icon.webp'
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import {useEthers, shortenAddress, useEtherBalance } from '@usedapp/core'

import {ethers} from "ethers";
import Skeleton from "@mui/material/Skeleton";

export default function TemporaryDrawer({open, onClose}) {
    const { account, deactivate} = useEthers();
    const balance = useEtherBalance(account);

    const deactivateWallet = () => {
        deactivate();
        localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    }

    return (
        <div>
            <React.Fragment key={'right'}>
                <Drawer
                    PaperProps={{sx: {width: {xs: '100%', md:420}}}}
                    anchor={'right'}
                    open={open}
                    onClose={onClose}
                >
                    <Box
                        sx={{width: {xs: '100%', md:420}, mt: '110px'}}
                        role="presentation"
                        onClick={onClose}
                        onKeyDown={onClose}
                    >
                        {<Box>
                            <Box sx={{display: 'flex', padding: '20px', justifyContent: 'space-between'}}>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <AccountCircleOutlinedIcon/>
                                        <Box sx={{
                                            display: 'flex',
                                            ml: '10px',
                                            fontWeight: 500,
                                            color: 'rgb(112, 122, 131)',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}>
                                        </Box>
                                    </Box>
                                   
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    fontWeight: 500,
                                    color: 'rgb(112, 122, 131)',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    {shortenAddress(account)}
                                 </Box>
                            </Box>
                            <Divider/>
                            <Box sx={{padding: '20px'}}>
                                <Box sx={{
                                    display: 'flex',
                                    border: '1px solid rgb(229, 232, 235)',
                                    borderRadius: '10px',
                                    justifyContent: 'center'
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <p style={{fontWeight: 500, fontSize: '14px', color: 'rgb(112, 122, 131)'}}>
                                             Balance
                                        </p>
                                        <Box>
                                            <img src={EthIcon} width={'30px'}  alt={'logo'} />
                                            <h4 style={{
                                                marginTop: 0,
                                                fontWeight: 600,
                                                fontSize: '20px',
                                                color: 'rgb(4, 17, 29)'
                                            }}>
                                                {balance === null ? <Skeleton animation="wave" variant="text" /> : Math.round(ethers.utils.formatEther(balance || 0) * 1e2) / 1e2 + ' ETH'}
                                            </h4>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{padding: '20px', display: 'flex', justifyContent: 'center'}}>
                                <Button sx={{backgroundColor: '#95c11f'}} onClick={()=>deactivateWallet()} variant='contained'>Disconnect</Button>
                            </Box>
                        </Box>}
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}