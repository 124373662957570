import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Donate from './donate';
import ThankYou from './thankyou';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import {DAppProvider, ChainId} from '@usedapp/core'


import Box from '@mui/material/Box';
import {ThemeProvider} from '@mui/material/styles';

import UI from './@components/UI';
import Themes from './@components/designSystem/themes';

const config = {
    networks: [ChainId.Mainnet, ChainId.Rinkeby, ChainId.Polygon, ChainId.Mumbai],
    multicallAddresses: {
        1337: '',
        1: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
        4: '0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821',
        137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
        80001: '0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc'
    }
}

const Index = () => {
    return (
        <Box>
            <UI.AppBar/>
            <Switch>
                <Route path="/thank-you" component={ThankYou}/>
                <Route path="/" component={Donate}/>
                <Redirect from='*' to="/"/>
            </Switch>
        </Box>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={''}>
            <ThemeProvider theme={Themes.Light}>
                <DAppProvider config={config}>
                    <Index/>
                </DAppProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
