import React from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Logo from "../../static/logo-full.png";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MoreIcon from "@mui/icons-material/MoreVert";
import {useHistory} from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SideDrawer from './SideDrawer';
import {useEthers} from '@usedapp/core';
import ConnectWallet from './ConnectWallet';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    navButton: {
        transitionDuration: '500ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionProperty: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter',
        '&:hover': {
            color: 'rgba(149, 193, 31, 1);',
            backgroundColor: 'transparent',
            '&:before': {
                width: '90%'
            }
        },
        '&:before': {
            content: "",
            position: 'absolute',
            bottom: '10px',
            right: 0,
            left: 0,
            backgroundColor: '#cae08f',
            display: 'block',
            height: '1px',
            width: '90%',
            transition: '.2s ease-in',
            margin: 'auto'
        }
    }
}));

const MaterialAppBar = () => {
    const classes = useStyles();
    const { account, chainId } = useEthers();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={()=>{history.push('/account'); handleMenuClose()}}>Profile</MenuItem>
            <MenuItem onClick={()=>{history.push('/account/settings'); handleMenuClose()}}>Settings</MenuItem>
            <MenuItem onClick={handleMenuClose}>Log out</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={()=>history.push('/')}>
                Donate
            </MenuItem>
            <MenuItem  onClick={() =>window.open('https://ukraine.qvrse.io/story/', '_blank')}>
                Story
            </MenuItem >
            <MenuItem onClick={() =>window.open('https://ukraine.qvrse.io/story/', '_blank')}>
               WeCare
            </MenuItem>
            <MenuItem onClick={() =>window.open('https://qvrse.io/', '_blank')}>
                Qvrse
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar  position="fixed" sx={{height: '110px', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white', color: 'rgb(53, 56, 64)', justifyContent: 'center', padding: {xs: '0 20px', md: '0 50px', l: '0 160px'}}}>
                <Toolbar sx={{justifyContent: 'space-between', height: '110px'}}>
                    <Box sx={{display: 'flex'}}>
                        <Box onClick={() => history.push('/')} sx={{
                            mr: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}>
                            <img src={Logo} width={'123px'} height={'28px'} alt={'logo'}/>
                        </Box>
                        <Box sx={{display: {xs: 'none', md: 'flex'}, ml: {xs: '0 25px', md: '45px', l: '145px'}}}>
                            <Button
                                classes={{root: classes.navButton}}
                                key={'donate'}
                                onClick={()=>history.push('/')}
                                sx={{
                                    my: 2,
                                    padding: '0px 20px',
                                    fontSize: '16px',
                                    color: 'rgb(53, 56, 64)',
                                    fontWeight: '600',
                                    fontFamily: 'Poppins, serif',
                                    textTransform: 'unset',
                                    display: 'block'
                                }}
                            >
                                Donate
                            </Button>
                            <Button
                                classes={{root: classes.navButton}}
                                key={'story'}
                                onClick={() =>window.open('https://ukraine.qvrse.io/story/', '_blank')}
                                sx={{
                                    my: 2,
                                    padding: '0px 20px',
                                    fontSize: '16px',
                                    color: 'rgb(53, 56, 64)',
                                    fontWeight: '600',
                                    fontFamily: 'Poppins, serif',
                                    textTransform: 'unset',
                                    display: 'block'
                                }}
                            >
                                Story
                            </Button>
                            <Button
                                classes={{root: classes.navButton}}
                                key={'wecare'}
                                onClick={() =>window.open('https://ukraine.qvrse.io/story/', '_blank')}
                                sx={{
                                    my: 2,
                                    padding: '0px 20px',
                                    fontSize: '16px',
                                    color: 'rgb(53, 56, 64)',
                                    fontWeight: '600',
                                    fontFamily: 'Poppins, serif',
                                    textTransform: 'unset',
                                    display: 'block'
                                }}
                            >
                                WeCare
                            </Button>
                            <Button
                                classes={{root: classes.navButton}}
                                key={'qvrse'}
                                onClick={() =>window.open('https://qvrse.io/', '_blank')}

                                sx={{
                                    my: 2,
                                    padding: '0px 20px',
                                    fontSize: '16px',
                                    color: 'rgb(53, 56, 64)',
                                    fontWeight: '600',
                                    fontFamily: 'Poppins, serif',
                                    textTransform: 'unset',
                                    display: 'block'
                                }}
                            >
                                Qvrse
                            </Button>



                        </Box>
                    </Box>

                    <ConnectWallet />

                    { account && chainId===4 &&  <Box sx={{display: 'flex'}}>
                            <IconButton

                                onClick={() => setDrawerOpen(!drawerOpen)}
                                size="large"
                                aria-label="wallet"
                                color="inherit"
                            >
                                <AccountBalanceWalletOutlinedIcon  sx={{fontSize: '2rem'}}/>
                            </IconButton>
                            <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    }
                </Toolbar>
            </AppBar>
            { drawerOpen && <SideDrawer open={true} onClose={()=>setDrawerOpen(false)} />}
            {renderMobileMenu}
            {renderMenu}
            <Toolbar sx={{height: '110px'}} variant="dense"/>
        </Box>
    );
}

export default MaterialAppBar;