import React from 'react';
import Gateway from '../@components/gateway';

const useEthPrice = () => {
    const gateway = Gateway.useGateway();
    const [ethPrice, setEthPrice] = React.useState();
    const [isFetchingEtherPrice, setFetchingEtherPrice] = React.useState(false);

    React.useEffect(()=>{
        const fetchData = async () => {
            setFetchingEtherPrice(true);
            const res = await gateway.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=EUR');
            const {data: {ethereum: {eur}}} = res;
            setEthPrice(eur);
            setFetchingEtherPrice(false);
        };
        fetchData();
    }, [gateway]);

    const convertEthToEur = (ethToConvert) => {
        return ethPrice * ethToConvert;
    }

    return {
        convertEthToEur,
        ethPrice,
        isFetchingEtherPrice
    };
};

export default useEthPrice;